
import { gsap } from 'gsap'

export default {
  name: 'Nav',
  data: () => ({
    tl: undefined,
  }),
  watch: {
    '$store.state.navigation.open': {
      async handler(val) {
        if (val) {
          await this.tl.play()
        } else {
          await this.tl.reverse()
        }
      },
    },
    $route: {
      handler() {
        this.$emit('close-menu')
      },
    },
  },
  mounted() {
    this.setup()
  },
  methods: {
    setup() {
      const className = 'overlay-open'
      const tl = gsap.timeline({
        paused: true,
        onComplete: () => document.documentElement.classList.add(className),
        onReverseComplete: () =>
          document.documentElement.classList.remove(className),
      })
      tl.to(this.$el, {
        x: '0%',
      })
      tl.from(
        this.$el.querySelectorAll('.main-menu__item'),
        {
          x: '-100%',
          stagger: 0.075,
        },
        '<'
      ).totalDuration(0.8)

      this.tl = tl
    },
  },
}
